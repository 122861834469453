const Projects = [
  {
    id: 1,
    url: "/project-2020/1",
    thumbnail: "/assets/models/textures/project_2021_01.jpg",
    category: "ライフサイエンス",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "コロナ渦における臨床実習の代替となる教育方法の検討",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "医学部附属病院",
    authorPosition: "副看護部長",
    authorName: "ウイリアムソン 彰子",
    portrait: null,
    video: null,
    overviewText: [
      <p>
        新型コロナウイルス感染症拡大により、2020年度は、看護教育カリキュラムに定められている臨地実習の98％が実施できず、臨床実習の代替となる対応が求められている。本プロジェクトでは、実臨床に即した患者事例を電子カルテから情報収集するのと同様の環境で提供する「模擬カルテシステム」を開発する。看護学生が学ぶ環境を保障し、アフターコロナにも看護学生が臨床的な学びが得られる場の提供につながることが期待できる。
      </p>,
    ],
    overviewImage: "/assets/images/project2020/1/1.jpg",
    resultText: [
      <p>
        2020年5月初旬よりシステム構築を開始し、6月22日よりプロトタイプの模擬カルテを用いた学生指導を開始した。学生への一方的な情報提供とならず、指導者との双方向性を確保したことが重要なポイントである。
      </p>,
      <p>[ 本システムの特徴 ]</p>,
      <ol>
        <li>学生に開示するカルテの範囲を制限</li>
        <li>学生の実習記録をシステム内で作成 指導者に提出可能</li>
        <li>学生へのコメント返却機能</li>
      </ol>,
    ],
    resultImage: "/assets/images/project2020/1/2.jpg",
    buisinessModelText: [<p>本学が有するシステム利用権について企業と著作権契約を結び、利用者に販売する。</p>],
    buisinessModelImage: "/assets/images/project2020/1/3.jpg",
    futurePlanText: [
      <p>本学保健学科、他6校の実習生（間延べ約6,600人）に対し、本システムの運用を開始する。</p>,
      <ul>
        <li>・利用者と共に、追加事例の作成、および教育評価を実施する。</li>
        <li>・システム内の情報は文字情報であるため、学生が入院患者をイメージしやすいように、視覚的な教材を開発する。</li>
      </ul>,
    ],
    futurePlanImage: null,
  },
  {
    id: 2,
    url: "/project-2020/2",
    thumbnail: "/assets/models/textures/project_2021_02.jpg",
    category: "ライフサイエンス",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "乳幼児期手話言語獲得支援における映像教材の開発、ならびに動画配信のもたらす効果の実証",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "大学院人間発達環境学研究科",
    authorPosition: "教授",
    authorName: "河﨑 佳子",
    portrait: null,
    video: null,
    overviewText: [
      <p>
        聴覚に障害のある乳幼児には、母語として手話言語（日本手話）を自然習得できる環境が必要である。しかし、聴覚障害児の9割は健聴親のもとに誕生するため、保護者や兄姉の健聴乳幼児も手話言語の習得が必要となる。本プロジェクトでは、その一助として、聴覚障害乳幼児とその家族を対象に、保護者にとっても楽しめ、親子のコミュニケーションと愛着形成が可能な手話言語の自然習得を促す映像教材を作成する。
      </p>,
    ],
    overviewImage: null,
    resultText: [
      <ol>
        <li>DVD教材の作成：24枚（0～3歳用、3～6歳用）（24週、120日分）</li>
        <li>インターネット経由で、28家族に視聴を依頼</li>
        <li>2ヶ月余りの毎日配信の後、アンケート調査を実施</li>
      </ol>,
      <p>(対象28家族のうち24家族から回答を得た。)</p>,
      <br />,
      <p>[ 成果 ]</p>,
      <p>
        アンケートの結果、ほぼ全ての子どもが家族と共に動画を視聴、子どもの興味の広がりや手話の発達に効果が見られた。保護者にも楽しめる内容で、親子間のコミュニケーションや保護者の手話習得にも役立ったことが明らかとなった。
      </p>,
    ],
    resultImage: "/assets/images/project2020/2/2.jpg",
    buisinessModelText: [<p>動画は、神戸大学とNPOが共同で新会社にライセンスし、新会社が販売を行う。</p>],
    buisinessModelImage: "/assets/images/project2020/2/3.jpg",
    futurePlanText: [
      <p>
        開発した映像教材は、教材内容を共同開発したNPOと協議の上、DVD販売やインターネット配信によってその商品化を検討する。サブスクリプション方式の導入等、課金方法について検討する。
      </p>,
      <p>2022年起業予定、収益のサイクル化を目指す。</p>,
    ],
    futurePlanImage: "/assets/images/project2020/2/4.jpg",
  },
  {
    id: 3,
    url: "/project-2020/3",
    thumbnail: "/assets/models/textures/project_2021_03.jpg",
    category: "ライフサイエンス",
    adoptionYear: "2020年",
    publicationPeriod: "2021年3月",
    title: "生体由来波形データおよび画像データを使用した疾患診断用マルチモーダル人工知能(AI)の開発",
    subtitle: "(プロトタイピングおよびバリエーション追加試験)",
    authorUniversity: "神戸大学",
    authorAffiliation: "大学院医学研究科",
    authorPosition: "大学院生(循環器内科)",
    authorName: "西森 誠",
    portrait: null,
    video: null,
    overviewText: [
      <p>
        現在、小児循環器疾患に対する健診は全国各地で実施されているが、小児循環器専門医は少数であり、専門でない医師が診断に携わるケースが多い。また、地方では医師そのものが少なく、医療格差は広がる傾向にある。そこで、小児の心電図に特化した診断補助システムを開発することで、医療格差の是正を図り、小児循環器疾患の早期発見・治療に向けた小児心電図AI診断ソフトウェアの開発を行う。
      </p>,
    ],
    overviewImage: "/assets/images/project2020/3/1.jpg",
    resultText: [
      <p>
        まずはスモールデータでAIモデルを作成を行った。特発性心筋症（肥大型心筋症）の患者と正常例を判定するモデルを作成し、最大93%の精度で判定することが可能となった。また、学習済みのAIモデルを搭載したWebアプリケーションを開発し、デモとしてユーザー（検診医師）に実際に使用してもらい、フィードバックを得た。
      </p>,
    ],
    resultImage: "/assets/images/project2020/3/2.jpg",
    buisinessModelText: [<p>小児検診事業者や心電図機器メーカーへライセンスを行う。</p>],
    buisinessModelImage: "/assets/images/project2020/3/3.jpg",
    futurePlanText: [
      <p>
        2021年度内の起業を予定。商用ソフトウェアの開発に向け、心電図のビッグデータの取得および商用モデルの開発・UIUXの開発を予定している（2021年度内実施）。また、医療機器認証の申請に向け、PMDAとの事前相談などの資料作成も同時に行っていく予定である。
      </p>,
    ],
    futurePlanImage: null,
  },
  {
    id: 4,
    url: "/project-2020/4",
    thumbnail: "/assets/models/textures/project_2021_04.jpg",
    category: "ライフサイエンス",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "性ステロイドホルモン増大による新規2型糖尿病予防法の開発",
    subtitle: "〜免疫調節システムに着目した基礎的メカニズムの解明〜",
    authorUniversity: "神戸大学",
    authorAffiliation: "人間発達環境学研究科",
    authorPosition: "准教授",
    authorName: "佐藤 幸治",
    portrait: null,
    video: null,
    overviewText: [
      <p>
        本プロジェクトでは、2型糖尿病予防のための新たな生活習慣の改善策の提示を目的とする。性ステロイドホルモン濃度を増加させる栄養成分であるジオスゲニン投与が、骨格筋培養細胞において、抗炎症作用を持つサイトカインである遺伝子の増加を介して2型糖尿病の予防・改善に関与していると仮説を立て、その遺伝子をノックアウトした時に、ジオスゲニン投与による骨格筋糖代謝調節経路の影響を検討する。現在、骨格筋培養細胞での遺伝子ノックアウトに成功し、今後、ジオスゲニン投与後に骨格筋糖代謝への影響を検討する。
      </p>,
    ],
    overviewImage: "/assets/images/project2020/4/1.jpg",
    resultText: [
      <p>
        ジオスゲニン摂取が、加齢や糖尿病、肥満によって低下している性ステロイドホルモンを増加させること、さらには、高血糖やインスリン抵抗性の2型糖尿病症状を改善することが明らかになっているが、その機序が不明のままであった。本プロジェクトでは、抗炎症作用を持つサイトカインである遺伝子を介して、骨格筋の糖代謝を改善することで、血糖値の改善に貢献していることが明らかになった。
      </p>,
    ],
    resultImage: "/assets/images/project2020/4/2.jpg",
    buisinessModelText: [
      <p>
        ジオスゲニン摂取による糖尿病・肥満症改善の機序の解明がなされれば、ジオスゲニン含有物の販売を検討している。沖縄県で栽培されているトゲドコロ芋（クーガ芋）に、ジオスゲニンが多く含有していることが明らかとなっており、トゲドコロ芋の粉末化の最適加工条件について企業と共同研究を実施している。
      </p>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <p>
        企業と連携し、科学的根拠に基づいた、商品開発を進めてく予定である。また、どのような商品にジオスゲニンを含めると、需要があるのか市場調査も必要である。
        2022年度以降にSTARTプロジェクト支援型に応募予定である。また、技術移転や共同研究を企業と行い、技術シーズの事業化に向けた活動を行っていく。
      </p>,
    ],
    futurePlanImage: null,
  },
  {
    id: 5,
    url: "/project-2020/5",
    thumbnail: "/assets/models/textures/project_2021_05.jpg",
    category: "環境エネルギー",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "気象モデルWRFを用いた洋上風況調査手法の実用化",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "海事科学研究科",
    authorPosition: "教授",
    authorName: "大澤 輝夫",
    portrait: null,
    video: null,
    overviewText: [
      <p>
        洋上風力発電所建設に向けた風況調査において、これまで陸上の風況調査で実績を有する数値流体力学（CFD）モデルに比べて、風況が大気安定度に大きく依存する洋上においては、熱力学的過程を考慮できる気象モデルの使用が合理的であると考えられる。しかしこれまで両モデルの洋上風況推定精度を直接的に比較した研究例はほとんどない。本プロジェクトでは、気象モデルWRF（Weather
        Research and Forecasting model）の洋上風況推定精度をCFDモデルと比較することにより、両者の特性を明らかにすると共に有用性の検討を行う。
      </p>,
    ],
    overviewImage: "/assets/images/project2020/5/1.jpg",
    resultText: [
      <p>
        今回の気象モデルWRFとCFDモデルの精度比較は、右図青矢印の部分に相当する。精度比較には和歌山県白浜町の観測値を用いた。洋上サイトでの風速・風向推定精度、沿岸サイトでの鉛直風速プロファイル、平均風速場等を検証した結果、推定精度が入力値に依存して不安定なCFDモデルに対して、WRFの有用性を示すことができた。
      </p>,
    ],
    resultImage: "/assets/images/project2020/5/2.jpg",
    buisinessModelText: [
      <p>
        WRFによる風況シミュレーションは現で最も精度が高い洋上風況推定手法であると考えられる。神戸大学でのシミュレーション技術向上と、その検証に不可欠な現場観測値が得られる風況コンサルティングサービスとを表裏一体とした、大学ならではのビジネスモデルを推進する。
      </p>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <p>
        事業化を実施するレラテック株式会社を2020年11月設立。風力発電に関わる発電事業者等に対して、WRFによる洋上風況調査技術を用いた風況コンサルティングサービスを本格化する予定である。特に、WRFによる風況シミュレーションとリモートセンシング機器（スキャニングライダー等）による現場観測とを組み合わせた高精度な風況調査手法をサービスの中核にしていく予定である。
      </p>,
    ],
    futurePlanImage: "/assets/images/project2020/5/4.jpg",
  },
  {
    id: 6,
    url: "/project-2020/6",
    thumbnail: "/assets/models/textures/project_2021_06.jpg",
    category: "ライフサイエンス",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "CT画像解析に基づく大動脈解離のAI診断を用いた診断システムの開発",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "医学部附属病院 心臓血管外科",
    authorPosition: "助教",
    authorName: "辻本 貴紀",
    portrait: null,
    video: null,
    overviewText: [
      <p>
        本プロジェクトでは、CT画像を基に大動脈解離を正確に診断するアルゴリズムの構築を目的とする。大動脈解離の診断では、時間的制約が高く、地域による診断格差が致命的になりうる。神戸大学医学部付属病院での豊富な大動脈解離のCTデータ及び治療経過を基に高精度のアルゴリズムを構築し、CTの診断ソフトウェアの一つの機能としての実装を目指す。急性大動脈解離により命を失う患者を減らし、医療従事者の負担軽減にもつながることが期待できる。
      </p>,
    ],
    overviewImage: "/assets/images/project2020/6/1.jpg",
    resultText: [
      <p>
        DICOM画像を基に学習を行うアルゴリズムを構築し、神戸大学医学部付属病院が保有する大動脈解離症例にて深層学習を行ったところ、造影CTのテストデータにおいて99%以上の精度で診断可能であった。本ソフトウェアの事業化に向けては、使用機会拡大のため、保険収載されたICTの為のプログラム医療機器への導入を目指している。
      </p>,
    ],
    resultImage: "/assets/images/project2020/6/2.jpg",
    buisinessModelText: [
      <h4>[ サブスクリプション型ビジネス ]</h4>,
      <p>年間利用料：定額</p>,
      <br />,
      <h4>[ ターゲット病院 ]</h4>,
      <p>CT撮像が可能な中・大規模病院 (約8,000機関)</p>,
      <br />,
      <h4>[ 市場規模 ]</h4>,
      <p>利用料：100万/年 (仮)、 市場規模：80億円、 シェア10%の場合、 売上8億円/年</p>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <p>
        2022年起業予定。本システムについて「大動脈解離の遠隔AI補助診断」として特許およびプログラム著作物等として知財を確保する。プログラム医療機器としての承認、または第三者認定機関からの認証を目指す。
      </p>,
    ],
    futurePlanImage: null,
  },
  {
    id: 7,
    url: "/project-2020/7",
    thumbnail: "/assets/models/textures/project_2021_07.jpg",
    category: "ものづくり",
    adoptionYear: "2020年",
    publicationPeriod: "2021年3月",
    title: "可食性コーティングに用いるフィルム開発",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "大学院農学研究科",
    authorPosition: "教授",
    authorName: "野村 啓一",
    portrait: null,
    video: null,
    overviewText: [
      <p>
        可食性薄膜フィルムで青果物をコーティングし、その貯蔵期間を延長する可食性コーティングは、SDGsが掲げられる今日にふさわしい貯蔵法といえる。しかし、フィルム素材と青果物の組み合わせに関する一般則がなく、その手法の開発は極めて効率が悪い。本プロジェクトでは、モデル系でフィルム特性を解明し、種々の青果物で検証することによって一般則を明らかにし、食品全般への応用の可能性を探る。
      </p>,
    ],
    overviewImage: "/assets/images/project2020/7/1.jpg",
    resultText: [
      <p>
        キトサンとアルギン酸ナトリウム（SA）では、SAの方がモデルフィルムを作成しやすいことから、当該フィルムの透湿度と酸素透過性を測定したところ、既存のプラスチックフィルムに比べて極めて高い酸素透過抑制能を認めた。また、この透過抑制能は、可塑剤などの添加に依存しなかった。一方で、透湿度が高すぎることから、SAを用いたコーティングは水分含量の低い青果物に適していると考えられ、実際にカットニンジンの重量減少抑制効果を認めた。
      </p>,
    ],
    resultImage: "/assets/images/project2020/7/2.jpg",
    buisinessModelText: [
      <p>
        青果物の貯蔵は、蒸散による重量減少の抑制も重要であり、今後改変を加えることで達成可能と考える。一方、食品業界において、酸化抑制は消費期限延長の最重要課題である。SAフィルムは酸化抑制に効果があることから、種々の加工食品の消費期限延長に寄与できると考える。
      </p>,
    ],
    buisinessModelImage: "/assets/images/project2020/7/3.jpg",
    futurePlanText: [
      <p>
        SAをベースとしたフィルムが、極めて高い酸素透過阻止力があることが明らかとなった。今後の実用化に向けては、より透湿性を抑える必要があるため、タンパク質添加の効果などを調査する予定である。またSAは、価格的にはやや高価なことから、代用できる多糖の探索も実施し、採算のとれるフィルム開発を行う。青果物に限らず食品全般の包装フィルムの開発に展開する予定であり、2022年度起業に向けて市場性の調査、およびフィルム開発を進めていく。
      </p>,
    ],
    futurePlanImage: null,
  },
  {
    id: 8,
    url: "/project-2020/8",
    thumbnail: "/assets/models/textures/project_2021_08.jpg",
    category: "ものづくり",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "三角柱マルチリモコンの製品化事業",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "大学院保健学研究科",
    authorPosition: "准教授",
    authorName: "長尾 徹",
    portrait: null,
    video: null,
    overviewText: [
      <p>
        地上波デジタル放送が開始されてから、テレビのリモコンは多機能・多ボタン化し、普段使わないボタンが存在する、誤って押した時に復帰する方法が分からないなど、高齢者等にとっては大変複雑な道具になっている。本プロジェクトでは、高齢者でも簡単に使え、取り扱いに知識や練習が必要ないマルチリモコンを開発する。
      </p>,
    ],
    overviewImage: "/assets/images/project2020/8/1.jpg",
    resultText: [
      <p>
        テレビの「電源・選局・音量」、照明の「電源」、エアコンの「電源」の三面があるリモコンとして、試作品を作製した。3Dプリンターによる制作であったことから、デザインには限界があったが、各ボタンへの学習方法は簡単になった。今後、試作品を用いたユーザー評価を行う予定である。
      </p>,
    ],
    resultImage: "/assets/images/project2020/8/2.jpg",
    buisinessModelText: [
      <p>
        本プロジェクトで完成度が向上した本リモコンは、在宅だけではなく病室や老人施設に設置することを想定している。テレビ等の操作に関わる介助時間を削減でき、介護者が他の介護に専念する時間が拡張される。市販化により社会貢献も期待される。
      </p>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <p>
        試作品を用いたユーザー評価を行い、市販できるような形状と機能を決め、金型を作る。製作を担当した㈱坪田測器および神戸医療産業都市推進機構を通して販売業者を選定する。その後、神戸市産業振興財団の神戸セレクションに申請し、神戸発のアイテムとして全国の商工会議所等へPRする。本リモコンにより、高齢者は間違った操作をすることがなくなるため、介護者を呼ばなくてもよい。コロナ禍が終息すれば、ビジネスホテル等でも利用できるか検討する。
      </p>,
    ],
    futurePlanImage: null,
  },
  {
    id: 9,
    url: "/project-2020/9",
    thumbnail: "/assets/models/textures/project_2021_09.jpg",
    category: "その他",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "消費者の深層心理を探求するための技法の実証的研究 及びその確立",
    subtitle: "～コラージュ法に焦点を当てて～",
    authorUniversity: "神戸大学",
    authorAffiliation: "大学院人間発達環境学研究科",
    authorPosition: "准教授",
    authorName: "伊藤 俊樹",
    portrait: null,
    video: null,
    overviewText: [
      <p>
        本プロジェクトでは、臨床心理学において人の無意識を知るために用いられるコラージュ法を用いることによって、従来のマーケティングリサーチでは探ることのできなかった消費者の深層心理・無意識を探る質的分析技法を確立することを目的とする。それによって、消費者の深層心理をターゲットにした企業の開発戦略、営業戦略等に資する情報を提供することを目指す。被験者の深層心理を分析するコラージュ法を活用し、消費者の深層心理から企業活動に有用なマーケティング戦略の立案に結び付ける手法を検討した。
      </p>,
    ],
    overviewImage: "/assets/images/project2020/9/1.jpg",
    resultText: [
      <p>分析対象: ドトールコーヒー、スターバックスコーヒー</p>,
      <p>被験者: 東京大学の学生 20名</p>,
      <p>分析手法: コラージュ作品の作成、及び作品に基づく深層面接</p>,
      <p>
        分析結果：各コーヒーショップに抱く深層のイメージと深層の自己イメージ、理想自己イメージとの関係が、コーヒーショップの好意度に関わっていることがコラージュ法を通して見出された。
      </p>,
    ],
    resultImage: null,
    buisinessModelText: [
      <h4>1.コラージュ法を用いたコンサルティングサービスの実施</h4>,
      <p>
        ・消費者の深層心理調査
        <br />
        ・開発戦略
        <br />
        ・営業戦略 等
      </p>,
      <h4>2.会社のリブランディング</h4>,
      <p>製品やサービスだけでなく、会社のイメージも分析対象とすることにより、会社自体のイメージ戦略立案をサポートする。</p>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <p>
        2021年3月、㈱日本消費者深層心理研究センターを設立。今後、当社が提案するマーケティングにおける消費者の深層心理調査を導入する顧客開拓を進める。消費者の深層心理を探ることにより、マーケティング戦略立案を行うメーカー、サービス業の企業、あるいは広告代理店を顧客ターゲットとする。セミナー、学会や日本マーケティング協会等の団体を通じて、質的分析に関心をもったマーケター、クライアントを対象に研修講座等を行い、顧客開拓をしていく予定である。
      </p>,
    ],
    futurePlanImage: null,
  },
  {
    id: 10,
    url: "/project-2020/10",
    thumbnail: "/assets/models/textures/project_2021_10.jpg",
    category: "その他",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "環境DNA分析手法を用いた生物調査手法の事業化に向けた研究",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "大学院人間発達環境学研究科",
    authorPosition: "准教授",
    authorName: "源 利文",
    portrait: null,
    video: null,
    overviewText: [
      <p>
        水の中や空気中などの環境中に存在する「環境DNA」を調べることで、どこにどのような生物がいるかを明らかにする手法が発展している。しかし、事業化するためには、実験室内における汚染の問題、適用可能な生物群の種類、分析にかかる時間などが課題として残されている。本プロジェクトでは、新規に検査手法を開発し、信頼性の高い解析サービスを提供できる体制を整えると共に、新たに昆虫のメタバーコーディング系を開発し、対象生物の適用範囲の拡大を試みた。
      </p>,
    ],
    overviewImage: "/assets/images/project2020/10/1.jpg",
    resultText: [
      <h4>1.コンタミネーション（汚染）フリーな検査環境の構築</h4>,
      <p>目標コンタミネーション率0.5%を下回る0.25%を達成</p>,
      <br />,
      <h4>2.要請の多いトンボ類の環境DNAメタバーコーディング系の開発に成功</h4>,
      <p>
        六甲山系で行ったテストでは、36種のトンボのDNAを検出
        <br />
        実際のトンボの分布をよく反映しているデータを取得
      </p>,
      <br />,
      <h4>3.環境DNAのオンサイト分析手法の最適化</h4>,
      <p>
        モバイルPCRを用いた環境DNAのオンサイト分析手法の最適化
        <br />
        現場で約20分で検出できる手法を開発
      </p>,
    ],
    resultImage: null,
    buisinessModelText: [
      <h4>1.環境アセスメント業者等</h4>,
      <p>トンボ類や両生類の検出に対するニーズ</p>,
      <br />,
      <h4>2.魚類の養殖事業者</h4>,
      <p>
        「病原体の検出」「ストレス値の見える化」などをオンサイトで行うことへの需要 ⇒ ニーズ本研究の成果によって市場のニーズを満たすことができることが分かった。
      </p>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <p>
        2021年3月、㈱日本消費者深層心理研究センターを設立。今後、当社が提案するマーケティングにおける消費者の深層心理調査を導入する顧客開拓を進める。消費者の深層心理を探ることにより、マーケティング戦略立案を行うメーカー、サービス業の企業、あるいは広告代理店を顧客ターゲットとする。セミナー、学会や日本マーケティング協会等の団体を通じて、質的分析に関心をもったマーケター、クライアントを対象に研修講座等を行い、顧客開拓をしていく予定である。
      </p>,
      <p>2022年2月現在、特定害虫の検出技術が確立され事業化検討が進んでいる。</p>,
    ],
    futurePlanImage: "/assets/images/project2020/10/4.jpg",
  },
  {
    id: 11,
    url: "/project-2020/11",
    thumbnail: "/assets/models/textures/project_2021_11.jpg",
    category: "ライフサイエンス",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "超音波振動を応用した体内埋め込み型医療機器ワイヤレス給電システムの開発",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "大学院海事科学研究科(兼)未来医工学研究開発センター",
    authorPosition: "准教授",
    authorName: "三島 智和",
    portrait: null,
    video: null,
    overviewText: [
      <p>
        従来、心臓ペースメーカなど体内埋め込み型医療機器は、内臓電池の寿命の度に取り換え手術を必要とし、感染症リスクなど患者の負担が大きかった。本プロジェクトでは、体内埋め込み型医療機器へ非侵襲にて電力を伝送する装置として、超音波振動を利用した非接触ワイヤレス給電（UWPT）システムの開発を行う。小型・高効率・低ノイズのUWPTシステム試作機を作製し、人体を模擬した給電環境における有用性を示した。
      </p>,
    ],
    overviewImage: "/assets/images/project2020/11/1.jpg",
    resultText: [
      <h4>1.電気モデリング手法の確立</h4>,
      <p>
        圧電素子対と医療用超音波ファントムにより構成した送受電
        <br />
        モジュール一体の周波数特性から、電気回路モデリング手法を確立
      </p>,
      <h4>2.電気系回路の最適設計プロセスを考案</h4>,
      <p>体外に設置する高周波発生装置（高周波インバータ）や共振回路、インピーダンス整合回路および体内の整流回路など</p>,
      <h4>3.試作機の作製</h4>,
      <p>300mW-40kHz試作機にて、約10%の伝送効率を達成</p>,
    ],
    resultImage: null,
    buisinessModelText: [
      <h4>1.心臓ペースメーカの共同開発</h4>,
      <p>医療機器メーカと共同で、本UWPTシステムを内蔵した心臓ペースメーカを開発</p>,
      <h4>2.大学発ベンチャーの設立</h4>,
      <p>新型心臓ペースメーカの医療機器承認申請を実施</p>,
      <h4>3.他の埋め込み型医療機器への適用拡大</h4>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <ul>
        <li>本事業の成果に関する特許出願を準備中である。</li>
        <li>2021年度JST‐STARTプロジェクト支援型に申請中である。</li>
        <li>STARTの採択を受けて、神戸大学医学部付属病院 循環器内科医師と試作機の動物実験を実施する予定である。</li>
        <li>2026年を目処に、神戸大学発ベンチャーを起業予定である。</li>
      </ul>,
    ],
    futurePlanImage: null,
  },
  {
    id: 12,
    url: "/project-2020/12",
    thumbnail: "/assets/models/textures/project_2021_12.jpg",
    category: "IT",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "高性能・超低消費電力プロセッサアーキテクチャの研究",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "大学院理学研究科",
    authorPosition: "教授",
    authorName: "牧野 淳一郎",
    portrait: null,
    video: null,
    overviewText: [
      <p>
        本研究代表者がこれまで開発してきたプロセッサは、SIMD動作する単純な要素プロセッサを多数集積する方式により、世界トップレベルの演算性能を実現してきた。また、汎用のプロセッサとしても、消費電力あたりの性能のランキングであるGreen500の2021年6月のランキングで21.1Gflops/Wを達成して第1位となった。本プロジェクトでは、これまで開発してきたプロセッサのアーキテクチャにさらに改良を加え、コンパイラ等のソフトウェアスタックとともに提供可能とすることで、高性能計算のためのプロセッサシステムの基礎を作ることを目標とする。
      </p>,
    ],
    overviewImage: null,
    resultText: [
      <h4>1.AI、スパコン向けのプロセッサ・アーキテクチャ論理設計</h4>,
      <p>2020年6月GREEN-500トップのMN-Core(PFNと共同開発）のベース設計をさらに改良し、深層学習以外でも高い性能を実現した。</p>,
      <h4>2.チップ内ネットワークの最適化</h4>,
      <p>
        深層学習だけでなく様々なアプリケーションを用いて最適化を実施。チップ内のコア数をnとすると、nの平方根程度の数に分割する2階層,ネットワークが望ましいと分かった（nが1万程度まで）。
      </p>,
      <h4>3.チップ内ネットワーク内のデータや階層間のデータ移動を表現する拡張文法の定義</h4>,
    ],
    resultImage: null,
    buisinessModelText: [
      <ul>
        <li>プロセッサコアIPを開発し、カスタマー企業に提供する。</li>
        <li>深層学習以外の多様なアプリケーションに適用可能なものにバリエーションを追加する。</li>
        <li>命令セットアーキテクチャとソフトウェアスタックを知的財産権として確保する。</li>
      </ul>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <p>
        本プロジェクトの成果を基に、プロセッサアーキテクチャ開発及び開発コンサルティング事業への展開を目指す。
        現在、知的財産権の確保と資金調達に向けて活動中である。
      </p>,
    ],
    futurePlanImage: null,
  },
  {
    id: 13,
    url: "/project-2020/13",
    thumbnail: "/assets/models/textures/project_2021_13.jpg",
    category: "ライフサイエンス",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "ヒトiPS細胞の心筋細胞分化に及ぼす血管新生促進ゲルの効果の検証",
    subtitle: null,
    authorUniversity: "神戸大学",
    authorAffiliation: "工学研究科",
    authorPosition: "准教授",
    authorName: "大谷 亨",
    portrait: null,
    video: null,
    overviewText: [
      <p>
        人工多能性幹細胞(iPS細胞)を用いて体内で心筋細胞分化を誘導するためには、体内での血管新生誘導が不可欠である。本プロジェクトでは、独自の血管新生促進ゲル（特開2019-195347）を用い、細胞成長因子のゲルからの放出性と細胞増殖との関連性を明らかにし、iPS細胞から分化誘導された心筋細胞に対する血管新生促進ゲルの影響を検証する。
      </p>,
    ],
    overviewImage: "/assets/images/project2020/13/1.jpg",
    resultText: [
      <p>
        研究の結果、放出速度と細胞増殖には相関がなく、ゲル内に成長因子が内包されることが細胞増殖に重要であることが明らかとなった。ゲル中に成長因子を包埋することにより成長因子の生物学的機能をより高めることができるゲルとして実用化が期待できる。
      </p>,
      <p>
        また、提案ゲルは、iPS由来の心筋細胞にも無害であったことから、体内で生着させる細胞治療においても適用可能と考えられる。成長因子の活性が再生医療に有効であるという考え方が適用でき、パラクライン効果を利用した再生医療に極めて有効である。
      </p>,
    ],
    resultImage: "/assets/images/project2020/13/2.jpg",
    buisinessModelText: [
      <p>
        対象とする臓器や疾患によって要求される再生・細胞医療技術が大きく異なることが見えてきたことから、アンメットニーズの高い治療領域での細胞治療市場へ展開することを目指す。販売企業と委託契約を結び、開発したゲルを、血管新生技術を必要とする大学・企業などに販売する。
      </p>,
    ],
    buisinessModelImage: null,
    futurePlanText: [
      <p>
        2021年度STARTプロジェクト支援型に応募予定である。種々の幹細胞培養の上澄みをゲル中に包埋し、パラクライン効果におけるゲルの有用性を明らかにし、これを権利化していく考えである。また、販売を担当する企業と委託契約を結び、前臨床試験や臨床試験を行う研究施設や大学などへ販売することを想定している。
      </p>,
    ],
    futurePlanImage: null,
  },
  {
    id: 14,
    url: "/project-2020/14",
    thumbnail: "/assets/models/textures/project_2021_18.jpg",
    category: "IT",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "農業生産現場における経営改善のためのIoTデバイス、シミュレータの開発",
    subtitle: null,
    authorUniversity: "大阪工業大学",
    authorAffiliation: "情報科学部 データサイエンス学科",
    authorPosition: "教授",
    authorName: "皆川 健多郎",
    portrait: "/assets/images/project2020/14/portrait.jpg",
    video: null,
    overviewText: [
      <p>解決したい課題・提供する価値： IoT技術の活用による農業生産性の向上</p>,
      <p>
        背景：
        日本の農業生産性は他国と比較しても低い上に、農業従事者の高齢化、人手不足も深刻化しており、農業生産性を向上させることは急務となっている。IoTを利用した生産性向上への取り組みも一部で導入が始まっているが、その導入コストの高さにより、中規模以下の農業従事者にはその取り組みが進んでいない。
      </p>,
      <p>
        技術の独創性・新規性：
        メッシュネットワークを活用した通信方式、土壌水分センシング機能を有したIoTデバイスと計測したデータをもとに作業計画を自動提案する仕組みを組み合わせ、従来の通信方法と比較し、低コストで農業生産現場における現場改善を実現させる手法を開発。
      </p>,
    ],
    overviewImage: "/assets/images/project2020/14/1.jpg",
    resultText: [
      <p>
        土壌水分センサと無線通信モジュールから構成されるIoTデバイスを開発し、2021年3月に中森農産株式会社の協力を得て、該社が保有する圃場にて実証実験を実施。　開発中のIoTデバイスを設置し、通信実験を行った結果、通信条件の不利な雨天時にも土壌水分量のモニタリングおよび計測データの伝送に成功した。
        <br />
        加えて、通信ネットワークの方式に関し、特許出願を行うべく、準備中。
      </p>,
    ],
    resultImage: null,
    buisinessModelText: null,
    buisinessModelImage: "/assets/images/project2020/14/3.jpg",
    futurePlanText: [
      <p>
        研究開発：
        今後は、広範囲エリアにおけるアドホック無線通信に関する研究開発を実施し、半導体不足による影響が解消されたのちに2021年3月の実証実験に続き、さらに広範囲エリアにおける通信実験の実施を検討予定。上記に加え、データを有効活用するために、すでに開発済みのアプリケーションの改良を施し、日本の農業の効率化に寄与していく。
      </p>,
      <ul>
        <li>・実証された通信技術をベースに、中小河川流域の氾濫、上下水道の内水氾濫検知などへの他用途への応用も平行して検討していく。</li>
      </ul>,
      <h4>事業化に向けた計画</h4>,
      <ul>
        <li>・実証実験の継続</li>
        <li>・知的戦略デザイナーアドバイザーサポートによる事業計画の策定と資本調達</li>
      </ul>,
    ],
    futurePlanImage: null,
  },
  {
    id: 15,
    url: "/project-2020/15",
    thumbnail: "/assets/models/textures/project_2021_19.jpg",
    category: "ものづくり",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "光照射によるカーボンカプセル材料創出法の開発",
    subtitle: null,
    authorUniversity: "大阪工業大学",
    authorAffiliation: "応用化学科",
    authorPosition: "教授",
    authorName: "藤井 秀司",
    portrait: "/assets/images/project2020/15/portrait.jpg",
    video: null,
    overviewText: [
      <p>解決したい課題： カーボンナノカプセルの容易な合成方法および生成物のコントロール手法（化学組成、粒径、シェル厚み等）の開発</p>,
      <p>
        背景：　各種材料の高機能化に期待されているカーボンナノカプセルであるが、製造コストが高く、実際に使用される機会が少ないのが現状。そのため、カーボンナノカプセルの安価な製造法の開発への期待が高くなっている。
      </p>,
      <p>技術の独創性・新規性：　光の照射という安価で簡便な手法によりカーボンナノカプセルの合成及び生成物のコントロール（粒径、シェル厚み等）か可能となる。</p>,
    ],
    overviewImage: "/assets/images/project2020/15/1.jpg",
    resultText: [
      <h4>1. 共役系高分子への近赤外光照射による炭化反応の評価</h4>,
      <p>
        共役系高分子であるポリピロール（PPy）を取り上げ、炭化反応および熱分解性高分子の分解反応を評価。その結果、近赤外光照射後、PPyが炭素材料に変換されていることを確認した。
      </p>,
      <h4>2. 熱分解高分子/共役系高分子 コアシェル粒子の精密合成・評価</h4>,
      <p>カーボンナノカプセル材料の前駆体として、汎用性高分子であるポリスチレン（PS）をPPyで覆った、PS/PPyコアシェル粒子の合成に成功した。</p>,
      <h4>3. カーボンナノカプセル材料の創出・評価</h4>,
      <p>
        生成PS/PPy粒子の乾燥体に近赤外光を照射することでPPyシェルの炭化およびPSコアの熱分解・除去を同時に行い、カーボンナノカプセル材料の合成を行った。さらに、1
        mmから80
        mmの粒子径を有するPS/PPy粒子でも合成が可能なことを実証。解明した相関関係に基づき、粒子径、壁厚みがコントロールされたカーボンナノカプセル材料のライブラリーを作製した。
      </p>,
    ],
    resultImage: null,
    buisinessModelText: null,
    buisinessModelImage: "/assets/images/project2020/15/3.jpg",
    futurePlanText: [
      <p>
        残課題の研究を継続するとともに、JVを視野に入れた共同研究先を研究支援・社会連携センターと探索し、STARTを含めた新たな大学発ベンチャーに結び付く新たな競争的資金の獲得（２０２２年度目標）により、早期の大学発ベンチャー、起業を目指す。
      </p>,
    ],
    futurePlanImage: null,
  },
  {
    id: 16,
    url: "/project-2020/16",
    thumbnail: "/assets/models/textures/project_2021_20.jpg",
    category: "ライフサイエンス",
    adoptionYear: "2020年",
    publicationPeriod: "2021年8月",
    title: "「機能性表示食品」取得を目指した臨床試験研究と成果物・検証プロセスの社会実装・事業化",
    subtitle: null,
    authorUniversity: "大阪工業大学",
    authorAffiliation: "総合人間学系教室",
    authorPosition: "准教授",
    authorName: "西脇 雅人",
    portrait: "/assets/images/project2020/16/portrait.jpg",
    video: null,
    overviewText: [
      <p>解決したい課題・提供する価値： 機能性食品の評価プロセスの確立。</p>,
      <p>
        背景：
        昨今の健康ブームを背景に、「機能性表示食品」や「特定保健用食品」の市場は年々拡大しているが、認証を取得のためのノウハウ等を持っている施設が非常に少なく、食品の評価を行い、認証を取得する事は極めて困難となっている。そのため、、機関が臨床研究や関与成分の定性・定量、および作用機序の考察を全面的に委託・代行・指導・サポートする本学の研究環境とそこでの研究成果を最大限利用した検証プロセスの社会実装・事業化を最終的に目標とする研究。
      </p>,
      <p>独創性・新規性：　臨床研究や関与成分の定性・定量、および作用機序の考察を全面的に委託・代行できるサービスを提供する体制を構築する。</p>,
    ],
    overviewImage: "/assets/images/project2020/16/1.jpg",
    resultText: [
      <p>
        本案件ではキルギス産の天然ハチミツの食品摂取効果、食品成分の検証を進め、このハチミツでの機能性食品の認可をとることで、認証取得のプロセス及びそのノウハウの取得を目指す。研究の結果、動脈壁の硬化度の低下、内皮細胞の活性度の上昇などに寄与することがわかった。また、天然白ハチミツの含有成分と特徴、効果をもたらす物質を特性するため、網羅的解析を行い、アノテーションのリストから、いくつかの動脈壁に作用する候補成分を得ることに成功した。
      </p>,
    ],
    resultImage: null,
    buisinessModelText: null,
    buisinessModelImage: "/assets/images/project2020/16/3.jpg",
    futurePlanText: [
      <h4>研究開発</h4>,
      <p>60人規模の臨床試験は完了。今後、膨大なデータを解析し、超音波エコー画像の分析や血液試料の生化学的分析など、最終的な効果に関し判定を実施予定。</p>,
      <ul>
        <li>・上記の結果に基づき、論文化を実施</li>
        <li>・候補物質から有効成分を絞り込み、有効成分の有用性の評価を予定</li>
      </ul>,
      <h4>事業化に向けた計画</h4>,
      <ul>
        <li>・評価プロセスのブラッシュアップを行いながら、ＳＴＡＲＴ等の事業化に向けたプロジェクトに応募することにより、早期の起業に取り組む。</li>
      </ul>,
    ],
    futurePlanImage: null,
  },
];

export default Projects;
